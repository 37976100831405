html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #f0f0f0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  /* min-height: 100%; */
}

#root {
  padding: 0;
  /* display: flex; */
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input.error {
  border-color: red;
}

@media screen and (min-width: 768px) {
  .ManageMemberPage {
    min-height: 100%;
    /* height: calc(100% - 160px); */
    padding-top: 148px;
    padding-bottom: 100px;
    background-color: #f0f0f0;
    /* background-color: white; */
  }
}

.HistoryPage {
  min-height: 100%;
  background-color: white;
}

.LicensePage {
  min-height: 100%;
  background-color: white;
}

.HistorySubSub {
  padding: 20px;
}

.RegisterPage {
  min-height: 100%;
  background-color: white;
}

.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}

.ProfilePage {
  min-height: 100%;
  background-color: white;
}

.ChangePWPage {
  min-height: 100%;
  background-color: white;
}

.ManageProductPage {
  min-height: 100%;
  background-color: white;
}

.SettingPage {
  min-height: 100%;
  background-color: white;
}

.DashboardPage {
  min-height: 100%;
  background-color: white;
}

.MuiList-padding {
  padding: 0;
}

.Footer {
  position: relative;
  transform: translateY(-100%);
}

#requestCode-label,
#licenseCode-label {
  padding-left: 4px;
}

#requestCode,
#licenseCode {
  padding-left: 8px;
}

.app button {
  text-transform: none;
}

::-ms-reveal {
  display: none;
}

.MuiFormHelperText-root.MuiFormHelperText-sizeSmall {
  font-size: 0.7rem;
  letter-spacing: 0.1px;
  line-height: 1;
  /* font-weight: 600; */
}
